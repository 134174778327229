const PORT = process.env.PORT;
const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const REACT_APP_PROTOCOL = process.env.REACT_APP_PROTOCOL;
const REACT_APP_HOST = process.env.REACT_APP_HOST;
const REACT_APP_SERVER_PORT = process.env.REACT_APP_SERVER_PORT;

const DEV_API_URL = `${REACT_APP_PROTOCOL}://${REACT_APP_HOST}:${REACT_APP_SERVER_PORT}`;
const NOT_DEV_API_URL = `${REACT_APP_PROTOCOL}://${REACT_APP_HOST}`;

// export const API_URL = `${REACT_APP_ENVIRONMENT == 'dev' ? DEV_API_URL : NOT_DEV_API_URL}/api/v1/dashboard`;
// export const STATIC_FILES_URL = `${REACT_APP_ENVIRONMENT == 'dev' ? DEV_API_URL : NOT_DEV_API_URL}/api/v1/`;

export const API_URL = `https://api.light-gate.net/api/v1`;
export const STATIC_FILES_URL = `https://api.light-gate.net/api/v1`;

export const LOG_IN_END_POINT = `${API_URL}/auth/login`;
export const LOGOUT_END_POINT = `${API_URL}/auth/logout`;
//static
export const GET_ALL_STATIC_DATA = `${API_URL}/dashboard/static`;

//Account
export const ADD_NEW_ACCOUNT = `${API_URL}/account`;
export const GET_ALL_ACCOUNTS = `${API_URL}/account`;
export const GET_ACCOUNT_BY_ID = `${API_URL}/account`;
export const GET_ALL_ACCOUNTS_BY_ROLE = `${API_URL}/account/get-in-role`;
export const UPDATE_ACCOUNT = `${API_URL}/account`;
export const UPDATE_MY_ACCOUNT = `${API_URL}/account/profile/update`;
export const GET_MY_ACCOUNT = `${API_URL}/account/profile`;
export const DELETE_ACCOUNT = `${API_URL}/account`;
export const RESET_ACCOUNT_PASSWORD = `${API_URL}/account/reset_pwd`;
export const RESET_MY_PASSWORD = `${API_URL}/account/profile/reset_pwd`;
export const TOGGLE_IS_ACCTIVE_ACCOUNT = `${API_URL}/account/is_active`;
//Languages
export const GET_ALL_LANGUAGES = `${API_URL}/dashboard/language`;
export const ADD_NEW_LANGUAGE = `${API_URL}/language`;
export const GET_IS_ACTIVE_LANGUGES = `${API_URL}/is_active`;
export const UPDATE_LANGUAGE = `${API_URL}/language`;
export const DELETE_LANGUAGE = `${API_URL}/language`;
export const TOGGLE_IS_ACCTIVE_LANGUAGE = `${API_URL}/language/is_active`;
//privileges

export const GET_ALL_PRIVILEGES = `${API_URL}/privilege`;

//roles
export const GET_ALL_ROLES = `${API_URL}/role`;
export const ADD_NEW_ROLE = `${API_URL}/role`;
export const UPDATE_ROLE = `${API_URL}/role`;
export const DELETE_ROLE = `${API_URL}/role`;

//files
export const UPLOAD_FILE = `${STATIC_FILES_URL}/file-manager/files`;

// content
export const GET_CONTENT_END_POINT = `${API_URL}/content/`;
export const GET_ABOUT_US_END_POINT = `${API_URL}/dashboard/about-us`;

// service
export const GET_ALL_SERVICES_END_POINT = `${API_URL}/dashboard/service`;
export const CREATE_NEW_SERVICE_END_POINT = `${API_URL}/dashboard/service`;
export const UPDATE_SERVICE_END_POINT = `${API_URL}/dashboard/service`;
export const DELETE_SERVICE_END_POINT = `${API_URL}/dashboard/service`;

// image slider
export const GET_ALL_IMAGE_SLIDERS_END_POINT = `${API_URL}/dashboard/image-slider`;
export const CREATE_NEW_IMAGE_SLIDER_END_POINT = `${API_URL}/dashboard/image-slider`;
export const UPDATE_IMAGE_SLIDER_END_POINT = `${API_URL}/dashboard/image-slider`;
export const TOGGLE_IS_FEATURE_IMAGE_SLIDER_END_POINT = `${API_URL}/dashboard/image-slider`;
export const DELETE_IMAGE_SLIDER_END_POINT = `${API_URL}/dashboard/image-slider`;

// category
export const GET_ALL_CATEGORIES_END_POINT = `${API_URL}/dashboard/category`;
export const CREATE_NEW_CATEGORY_END_POINT = `${API_URL}/dashboard/category`;
export const UPDATE_CATEGORY_END_POINT = `${API_URL}/dashboard/category`;
export const TOGGLE_IS_FEATURE_CATEGORY_END_POINT = `${API_URL}/dashboard/category`;
export const DELETE_CATEGORY_END_POINT = `${API_URL}/dashboard/category`;

// color
export const GET_ALL_COLORS_END_POINT = `${API_URL}/dashboard/color`;
export const CREATE_NEW_COLOR_END_POINT = `${API_URL}/dashboard/color`;
export const UPDATE_COLOR_END_POINT = `${API_URL}/dashboard/color`;
export const TOGGLE_IS_ENABLED_COLOR_END_POINT = `${API_URL}/dashboard/color`;
export const DELETE_COLOR_END_POINT = `${API_URL}/dashboard/color`;

// messages
export const GET_MY_ALL_MESSAGES_END_POINT = `${API_URL}/sales-manager-message/get-my-messages`;
export const MARK_MESSAGE_AS_READ_END_POINT = `${API_URL}/sales-manager-message/mark-as-read/`;
export const MARK_MY_ALL_MESSAGES_AS_READ_END_POINT = `${API_URL}/sales-manager-message/mark-all-as-read`;
export const DELETE_MESSAGE_END_POINT = `${API_URL}/sales-manager-message/`;
// product
export const GET_ALL_PRODUCT_END_POINT = `${API_URL}/dashboard/product`;
export const CREATE_NEW_PRODUCT_END_POINT = `${API_URL}/dashboard/product`;
export const UPDATE_PRODUCT_END_POINT = `${API_URL}/dashboard/product`;
export const TOGGLE_IS_FEATURE_PRODUCT_END_POINT = `${API_URL}/dashboard/product`;
export const DELETE_PRODUCT_END_POINT = `${API_URL}/dashboard/product`;

//product picture
export const CREATE_NEW_PRODUCT_PICTURE_END_POINT = `${API_URL}/dashboard/product/product-picture`;

// client
export const GET_ALL_CLIENTS_END_POINT = `${API_URL}/dashboard/client`;
export const CREATE_NEW_CLIENT_END_POINT = `${API_URL}/dashboard/client`;
export const UPDATE_CLIENT_END_POINT = `${API_URL}/dashboard/client`;
export const DELETE_CLIENT_END_POINT = `${API_URL}/dashboard/client`;

//company profile
export const GET_COMPANY_PROFILE_END_POINT = `${API_URL}/dashboard/company-profile`;
//Contact Us
export const GET_CONTACT_US = `${API_URL}/dashboard/contact-us`;